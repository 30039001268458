<h1 mat-dialog-title>{{ 'device.create' | translate }}</h1>
<div mat-dialog-content class="mat-typography">
    <p class="bg-orange-400 p-2">
        {{ 'device.create.disclaimer' | translate }}
    </p>
    <div [formGroup]="form" class="flex flex-col gap-2">
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.identifier' | translate }}
            </div>
            <sz-input formControlName="identifier" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'customer' | translate }}
            </div>
            <sz-select
                formControlName="customerId"
                [options]="customerOptions"
            />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.cpu_id' | translate }}
            </div>
            <sz-input formControlName="cpuId" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.uuid' | translate }}
            </div>
            <sz-input formControlName="uuid" />
        </div>
        <div>
            <div class="mat-subtitle-2">
                {{ 'device.serial_number' | translate }}
            </div>
            <sz-input formControlName="serialNumber" />
        </div>
        <sz-button
            size="large"
            text="device.generate_ids"
            bgColor="primary"
            icon="identifier"
            (click)="generateUUID()"
        />
    </div>
    <sz-dialog-button-bar
        [primaryDisabled]="!form.valid"
        (primaryClick)="dialogRef.close(form.value)"
        (secondaryClick)="dialogRef.close()"
    />
</div>
