import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api/api.service';
import { ensureLoaded } from 'src/app/services/api/dynamic-api';
import { SelectOption } from 'src/app/settings/settings.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-create-device-dialog',
    templateUrl: './create-device-dialog.component.html',
})
export class CreateDeviceDialog implements OnInit {
    form = new FormGroup({
        identifier: new FormControl('', Validators.required),
        customerId: new FormControl('', Validators.required),
        cpuId: new FormControl('', Validators.required),
        serialNumber: new FormControl('', Validators.required),
        uuid: new FormControl('', Validators.required),
    });
    customerOptions: SelectOption[];

    constructor(
        public dialogRef: MatDialogRef<CreateDeviceDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        private api: ApiService,
    ) {}

    async ngOnInit() {
        await ensureLoaded([this.api.organizations]);
        this.customerOptions = this.api.organizations
            .current()
            .map(({ name, id }) => ({ name, value: id }));
    }

    generateUUID() {
        const randomNumber = Math.floor(Math.random() * 90000000) + 10000000;
        this.form.get('cpuId').setValue(uuidv4());
        this.form.get('uuid').setValue(uuidv4());
        this.form.get('serialNumber').setValue(randomNumber.toString());
    }
}
