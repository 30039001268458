import { Component, Inject, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { SnackBarService } from 'src/app/services/snack-bar/snack-bar.service';
import { log } from 'src/app/shared/log';

interface AuthInfoDialogData {
    token: string;
    cpu_id: string;
    uuid: string;
}
@Component({
    templateUrl: './device-auth-info-dialog.component.html',
})
export class DeviceAuthInfoDialog {
    private clipboard = inject(ClipboardService);
    private snackBar = inject(SnackBarService);
    constructor(
        public dialogRef: MatDialogRef<DeviceAuthInfoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: AuthInfoDialogData,
    ) {}

    copyData() {
        try {
            this.clipboard.copy(JSON.stringify(this.data));
            this.snackBar.open('device.auth_info.copy.success');
            this.dialogRef.close();
        } catch (error) {
            log.tag('DeviceAuthInfoDialog').error('Error copying data:', error);
        }
    }
}
